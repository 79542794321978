// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-complete-js": () => import("./../src/pages/application-complete.js" /* webpackChunkName: "component---src-pages-application-complete-js" */),
  "component---src-pages-book-consultation-index-js": () => import("./../src/pages/book-consultation/index.js" /* webpackChunkName: "component---src-pages-book-consultation-index-js" */),
  "component---src-pages-careers-all-roles-index-js": () => import("./../src/pages/careers/all-roles/index.js" /* webpackChunkName: "component---src-pages-careers-all-roles-index-js" */),
  "component---src-pages-careers-hide-widget-js": () => import("./../src/pages/careers/hideWidget.js" /* webpackChunkName: "component---src-pages-careers-hide-widget-js" */),
  "component---src-pages-careers-index-js": () => import("./../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-music-teachers-buckinghamshire-js": () => import("./../src/pages/careers/music-teachers/buckinghamshire.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-buckinghamshire-js" */),
  "component---src-pages-careers-music-teachers-camberley-js": () => import("./../src/pages/careers/music-teachers/camberley.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-camberley-js" */),
  "component---src-pages-careers-music-teachers-colchester-js": () => import("./../src/pages/careers/music-teachers/colchester.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-colchester-js" */),
  "component---src-pages-careers-music-teachers-farnborough-js": () => import("./../src/pages/careers/music-teachers/farnborough.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-farnborough-js" */),
  "component---src-pages-careers-music-teachers-hitchin-js": () => import("./../src/pages/careers/music-teachers/hitchin.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-hitchin-js" */),
  "component---src-pages-careers-music-teachers-leeds-js": () => import("./../src/pages/careers/music-teachers/leeds.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-leeds-js" */),
  "component---src-pages-careers-music-teachers-lincolnshire-js": () => import("./../src/pages/careers/music-teachers/lincolnshire.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-lincolnshire-js" */),
  "component---src-pages-careers-music-teachers-london-js": () => import("./../src/pages/careers/music-teachers/london.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-london-js" */),
  "component---src-pages-careers-music-teachers-northampton-js": () => import("./../src/pages/careers/music-teachers/northampton.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-northampton-js" */),
  "component---src-pages-careers-music-teachers-oxford-js": () => import("./../src/pages/careers/music-teachers/oxford.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-oxford-js" */),
  "component---src-pages-careers-music-teachers-reading-js": () => import("./../src/pages/careers/music-teachers/reading.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-reading-js" */),
  "component---src-pages-careers-music-teachers-stevenage-js": () => import("./../src/pages/careers/music-teachers/stevenage.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-stevenage-js" */),
  "component---src-pages-careers-music-teachers-wokingham-js": () => import("./../src/pages/careers/music-teachers/wokingham.js" /* webpackChunkName: "component---src-pages-careers-music-teachers-wokingham-js" */),
  "component---src-pages-careers-musician-talent-pool-index-js": () => import("./../src/pages/careers/musician-talent-pool/index.js" /* webpackChunkName: "component---src-pages-careers-musician-talent-pool-index-js" */),
  "component---src-pages-careers-vocalists-js": () => import("./../src/pages/careers/vocalists.js" /* webpackChunkName: "component---src-pages-careers-vocalists-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contacts-js": () => import("./../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-cookie-policy-index-js": () => import("./../src/pages/cookie-policy/index.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-js" */),
  "component---src-pages-feelgoodfriday-index-js": () => import("./../src/pages/feelgoodfriday/index.js" /* webpackChunkName: "component---src-pages-feelgoodfriday-index-js" */),
  "component---src-pages-foundation-js": () => import("./../src/pages/foundation.js" /* webpackChunkName: "component---src-pages-foundation-js" */),
  "component---src-pages-how-to-get-rocksteady-lessons-for-free-index-js": () => import("./../src/pages/how-to-get-rocksteady-lessons-for-free/index.js" /* webpackChunkName: "component---src-pages-how-to-get-rocksteady-lessons-for-free-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-for-parents-index-js": () => import("./../src/pages/info-for-parents/index.js" /* webpackChunkName: "component---src-pages-info-for-parents-index-js" */),
  "component---src-pages-info-for-schools-index-js": () => import("./../src/pages/info-for-schools/index.js" /* webpackChunkName: "component---src-pages-info-for-schools-index-js" */),
  "component---src-pages-instruments-index-js": () => import("./../src/pages/instruments/index.js" /* webpackChunkName: "component---src-pages-instruments-index-js" */),
  "component---src-pages-making-mistakes-final-video-js": () => import("./../src/pages/making-mistakes-final-video.js" /* webpackChunkName: "component---src-pages-making-mistakes-final-video-js" */),
  "component---src-pages-podcast-js": () => import("./../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-privacy-notice-index-js": () => import("./../src/pages/privacy-notice/index.js" /* webpackChunkName: "component---src-pages-privacy-notice-index-js" */),
  "component---src-pages-recruitment-of-ex-offenders-js": () => import("./../src/pages/recruitment-of-ex-offenders.js" /* webpackChunkName: "component---src-pages-recruitment-of-ex-offenders-js" */),
  "component---src-pages-rocksteady-anomaly-overcoming-nerves-js": () => import("./../src/pages/rocksteady_anomaly_overcoming_nerves.js" /* webpackChunkName: "component---src-pages-rocksteady-anomaly-overcoming-nerves-js" */),
  "component---src-pages-rocksteady-anomaly-teamwork-js": () => import("./../src/pages/rocksteady_anomaly_teamwork.js" /* webpackChunkName: "component---src-pages-rocksteady-anomaly-teamwork-js" */),
  "component---src-pages-rocksteady-digital-media-pack-general-js": () => import("./../src/pages/rocksteady-digital-media-pack-general.js" /* webpackChunkName: "component---src-pages-rocksteady-digital-media-pack-general-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-terms-conditions-marketing-preferences-terms-conditions-js": () => import("./../src/pages/terms-conditions/marketing-preferences-terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-marketing-preferences-terms-conditions-js" */),
  "component---src-pages-terms-conditions-rtv-terms-conditions-js": () => import("./../src/pages/terms-conditions/rtv-terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-rtv-terms-conditions-js" */),
  "component---src-pages-video-how-to-rah-js": () => import("./../src/pages/video/how-to-rah.js" /* webpackChunkName: "component---src-pages-video-how-to-rah-js" */),
  "component---src-pages-video-rehearse-at-home-js": () => import("./../src/pages/video/rehearse-at-home.js" /* webpackChunkName: "component---src-pages-video-rehearse-at-home-js" */),
  "component---src-pages-video-rocksteady-at-home-js": () => import("./../src/pages/video/rocksteady-at-home.js" /* webpackChunkName: "component---src-pages-video-rocksteady-at-home-js" */),
  "component---src-pages-video-rocksteady-at-home-promo-js": () => import("./../src/pages/video/rocksteady-at-home-promo.js" /* webpackChunkName: "component---src-pages-video-rocksteady-at-home-promo-js" */),
  "component---src-pages-video-week-10-js": () => import("./../src/pages/video/week-10.js" /* webpackChunkName: "component---src-pages-video-week-10-js" */),
  "component---src-pages-video-week-2-js": () => import("./../src/pages/video/week-2.js" /* webpackChunkName: "component---src-pages-video-week-2-js" */),
  "component---src-pages-video-week-3-js": () => import("./../src/pages/video/week-3.js" /* webpackChunkName: "component---src-pages-video-week-3-js" */),
  "component---src-pages-video-week-3-lowdown-js": () => import("./../src/pages/video/week-3-lowdown.js" /* webpackChunkName: "component---src-pages-video-week-3-lowdown-js" */),
  "component---src-pages-video-week-4-dua-lipa-js": () => import("./../src/pages/video/week-4-dua-lipa.js" /* webpackChunkName: "component---src-pages-video-week-4-dua-lipa-js" */),
  "component---src-pages-video-week-4-js": () => import("./../src/pages/video/week-4.js" /* webpackChunkName: "component---src-pages-video-week-4-js" */),
  "component---src-pages-video-week-5-js": () => import("./../src/pages/video/week-5.js" /* webpackChunkName: "component---src-pages-video-week-5-js" */),
  "component---src-pages-video-week-6-js": () => import("./../src/pages/video/week-6.js" /* webpackChunkName: "component---src-pages-video-week-6-js" */),
  "component---src-pages-video-week-6-this-is-me-js": () => import("./../src/pages/video/week-6-this-is-me.js" /* webpackChunkName: "component---src-pages-video-week-6-this-is-me-js" */),
  "component---src-pages-video-week-9-js": () => import("./../src/pages/video/week-9.js" /* webpackChunkName: "component---src-pages-video-week-9-js" */),
  "component---src-pages-watch-video-js": () => import("./../src/pages/watch-video.js" /* webpackChunkName: "component---src-pages-watch-video-js" */),
  "component---src-pages-wellbeing-assembly-index-js": () => import("./../src/pages/wellbeing-assembly/index.js" /* webpackChunkName: "component---src-pages-wellbeing-assembly-index-js" */),
  "component---src-pages-zoom-lessons-index-js": () => import("./../src/pages/zoom-lessons/index.js" /* webpackChunkName: "component---src-pages-zoom-lessons-index-js" */)
}

